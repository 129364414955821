<template>
    <floating-box :style="{ top: '180px', left: '10px' }">
        <h4>已选择的点：</h4>
        <ul>
            <li v-for="(point, index) in points" :key="index">
                {{ `经度：${point.lng}, 纬度：${point.lat}` }}
                <button class="delete-button" @click="$emit('delete', index)">删除</button>
            </li>
        </ul>
    </floating-box>
</template>

<script>
import FloatingBox from "./FloatingBox.vue";

export default {
    components: { FloatingBox },
    props: {
        points: Array
    }
};
</script>

<style>
.delete-button {
    float: right;
    background-color: #ff4d4f;
    color: white;
    border: none;
    cursor: pointer;
    padding: 3px 6px;
    border-radius: 3px;
}
</style>