<template>
    <floating-box :style="{ top: '10px', left: '10px' }">
        <input v-model="query" type="text" placeholder="输入地点..." />
        <button @click="search">搜索</button>
    </floating-box>
</template>

<script>
import FloatingBox from "./FloatingBox.vue";

export default {
    components: { FloatingBox },
    data() {
        return {
            query: ""
        };
    },
    methods: {
        search() {
            if (!this.query.trim()) {
                alert("请输入查询内容！");
                return;
            }
            AMap.plugin("AMap.PlaceSearch", () => {
                const placeSearch = new AMap.PlaceSearch({ map: null });
                placeSearch.search(this.query, (status, result) => {
                    if (status === "complete" && result.poiList.pois.length) {
                        const position = result.poiList.pois[0].location;
                        if (confirm(`是否添加此地点？\n名称：${result.poiList.pois[0].name}`)) {
                            this.$emit("add-point", position);
                        }
                    } else {
                        alert("未找到相关地点，请重试。");
                    }
                });
            });
        }
    }
};
</script>