<template>
    <div id="app">
        <!-- 地图容器 -->
        <div id="map-container"></div>
        <!-- 搜索框组件 -->
        <search-box @add-point="addPointToRoute" />
        <!-- 路线生成按钮 -->
        <floating-box :style="{ top: '100px', left: '10px' }">
            <button @click="generateRoute">生成路线</button>
        </floating-box>
        <!-- 点列表 -->
        <point-list :points="selectedPoints" @delete="deletePoint" @update-order="updatePointsOrder" />
        <!-- 路线列表 -->
        <route-list :routes="polyLines" @delete="deleteRoute" />
    </div>
</template>

<script>
window._AMapSecurityConfig = {
            securityJsCode: '9ab3b4c47fbaebee058cede863ec00e6'}
import SearchBox from "./components/SearchBox.vue";
import FloatingBox from "./components/FloatingBox.vue";
import PointList from "./components/PointList.vue";
import RouteList from "./components/RouteList.vue";
import { loadAMap } from "./utils/loadAMap";
export default {
    data() {
        return {
            map: null
        };
    },
    async mounted() {
        try {
            const AMap = await loadAMap();
        } catch (err) {
            console.error("高德地图加载失败：", err);
        }
        // 初始化地图
        this.map = new AMap.Map("map-container", {
            zoom: 11,
            center: [118.930925, 32.114458]
        });

        // 地图右键点击添加点
        this.map.on("rightclick", (e) => {
            const position = e.lnglat;
            if (confirm(`是否添加此点？\n经度：${position.lng}\n纬度：${position.lat}`)) {
                this.addPointToRoute(position);
            }
        });
    },
    methods: {
        initMap(AMap) {
            this.map = new AMap.Map("map-container", {
                zoom: 11,
                center: [118.930925, 32.114458]
            });
        }
    },
    components: { SearchBox, FloatingBox, PointList, RouteList },
    data() {
        return {
            map: null, // 地图实例
            selectedPoints: [], // 已选择的点
            selectedPointMarkers: [], // 已选择的点的标记
            polyLines: [], // 生成的路线
        };
    },
    methods: {
        addPointToRoute(position) {
            // 添加点
            this.selectedPoints.push(position);
            // 在地图上添加标记
            const marker = new AMap.Marker({
                position,
                map: this.map,
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png"
            });
            this.selectedPointMarkers.push(marker);
        },
        deletePoint(index) {
            // 删除点
            this.selectedPoints.splice(index, 1);
            // 删除标记
            this.selectedPointMarkers[index].setMap(null);
        },
        updatePointsOrder(newOrder) {
            this.selectedPoints = newOrder;
        },
        generateRoute() {
            if (this.selectedPoints.length < 2) {
                alert("请选择至少两个点以生成路线");
                return;
            }
            AMap.plugin("AMap.Driving", () => {
                const driving = new AMap.Driving({
                    map: this.map,
                    showTraffic: false
                });
                driving.search(
                    this.selectedPoints[0],
                    this.selectedPoints[this.selectedPoints.length - 1],
                    { waypoints: this.selectedPoints.slice(1, -1) },
                    (status, result) => {
                        if (status === "complete" && result.routes?.length > 0) {
                            const route = result.routes[0];
                            const polyline = new AMap.Polyline({
                                path: route.steps.flatMap((step) => step.path),
                                strokeColor: "#00FF00",
                                strokeWeight: 5,
                                map: this.map
                            });
                            this.polyLines.push(polyline);
                        } else {
                            alert("路线生成失败，请重试：", result);
                        }
                    }
                );
            });
        },
        deleteRoute(index) {
            this.polyLines[index].setMap(null); // 从地图中移除
            this.polyLines.splice(index, 1);
        }
    }
};
</script>

<style>
#map-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}
</style>