export function loadAMap() {
    return new Promise((resolve, reject) => {
        if (typeof AMap !== "undefined") {
            resolve(AMap); // 如果已加载，直接返回
        } else {
            // 动态加载脚本
            const script = document.createElement("script");
            script.src = "https://webapi.amap.com/maps?v=2.0&key=70aa1176027615dd6a73bc609e85228e";
            script.onload = () => {
                resolve(AMap); // 加载完成
            };
            script.onerror = (err) => {
                reject(err); // 加载失败
            };
            document.head.appendChild(script);
        }
    });
}
