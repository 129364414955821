<template>
    <floating-box :style="{ top: '370px', left: '10px' }">
        <h4>生成的路线：</h4>
        <ul>
            <li v-for="(route, index) in routes" :key="index">
                路线 {{ index + 1 }}
                <button class="delete-button" @click="$emit('delete', index)">删除</button>
            </li>
        </ul>
    </floating-box>
</template>

<script>
import FloatingBox from "./FloatingBox.vue";

export default {
    components: { FloatingBox },
    props: {
        routes: Array
    }
};
</script>