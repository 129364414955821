<template>
  <div class="floating-box" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    style: Object
  }
};
</script>

<style>
.floating-box {
  position: absolute;
  width: 200px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1000;
}
</style>